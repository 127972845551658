import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import apiManager from "../../utils/apiManager";
import {simpleErrorAlert} from "../../utils/util";
import LoadingScreen from "../loadingScreen/loadingScreen";


export default function DownloadFile(this: any) {
    const {token} = useParams<{ token?: string }>();
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [fileName, setFileName] = useState<string>('');
    const [downloadUrl, setDownloadUrl] = useState<string>('')
    const downloadAnchor = useRef(null);

    function changeLoading(show: boolean, text: string = '') {
        setLoadingText(text)
        setLoading(show)
    }

    useEffect(() => {
        async function downloadFile() {
            try {
                changeLoading(true, 'Getting your file ready to download')
                let res = await apiManager.getFileInfo(token);
                setFileName(res.fileName)
                setDownloadUrl(res.url)
                // @ts-ignore
                downloadAnchor.current.click()
                changeLoading(false)
            } catch (ex: any) {
                let responseObj = ex.response.data.text();
                changeLoading(false)
                await simpleErrorAlert(responseObj)
                window.location.href = "https://www.decode.com/summarydata/";
            }
        }
        downloadFile().catch(err => console.error(err))
    }, [token]);

    return (
        <>
            {loading &&
            <LoadingScreen text1={loadingText}/>
            }
            <h3>The download for <strong>{fileName}</strong> will start in just a moment</h3>
            <a style={{"display": "none"}}
               download={fileName}
               href={downloadUrl}
               ref={downloadAnchor}
            >download it</a>
        </>

    );
}