import axios from "axios";

const manager = {
    submitForm: async (body, config) => {
        return await axios.post(`/api/form`, body, config)
    },
    downloadFile: async (token, file) => {
        return await axios.get(`/s3/download`, {
            params: {
                file: file,
                token: token
            },
            responseType: "arraybuffer"
        });
    },
    getFileSize: async (fileFolder, fileName) => {
        let res = await axios.get(`/s3/fileSize`, { params: { fileFolder, fileName } })
        return res.data
    },
    getFileInfo: async (token, file) => {
        let res = await axios.get(`/s3/fileInfo`, { params: { token, file } })
        return res.data
    },
    getFolderInfo: async (folderName) => {
        let res = await axios.get(`/s3/folderInfo`, { params: { folderName } });
        return res.data
    },
    getFilesInFolder: async (token) => {
        let res = await axios.get(`/s3/folder`,{ params: { token } });
        return res.data;
    }
};

export default manager;