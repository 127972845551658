import React from 'react';
import './App.css';
import Nav from './components/nav/nav'
import Form from './components/form/form'
import S3Grid from './components/S3grid/S3grid'
import DownloadFile from './components/downloadFile/downloadFile'
import Page404 from "./components/Page404/Page404";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

import "./stylesheets/style.css"
import "./stylesheets/decode.css"

function App() {
    return (
        <div className="App">
            <Nav/>
            <div className="container">
                <Router>
                    <Routes>
                        <Route path="/form/folder/:folderName" element={<Form isFolder={true}/>}/>
                        <Route path="/form/:fileFolder/:fileName" element={<Form isFolder={false}/>}/>
                        <Route path="/folder/:token" element={<S3Grid/>}/>
                        <Route path="/file/:token" element={<DownloadFile/>}/>
                        <Route path='*' element={<Page404/>}/>
                    </Routes>
                </Router>
            </div>
        </div>
    );
}

export default App;
