import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import fileDownload from "js-file-download";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import apiManager from "../../utils/apiManager";
import {useCurrentWidth} from "./useCurrentWidth";
import LoadingScreen from "../loadingScreen/loadingScreen";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {niceBytes, simpleErrorAlert} from "../../utils/util";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

export default function S3grid(this: any) {
    const {token} = useParams<{ token?: string }>();
    const [gridApi, setGridApi] = useState<any>(null);
    const [rowData, setRowData] = useState([] as any[]);
    const [selectedRows, setSelectedRows] = useState([] as any[]);
    const [data, setData] = useState([] as any[]);
    const [pageTitle, setPageTitle] = useState('');
    const [readMeLink, setReadMeLink] = useState('');
    const [dlTokenValidDays, setDlTokenValidDays] = useState(0 as number);
    const [open, setOpen] = useState(false);
    const [urls, setUrls] = useState('');
    const [tabValue, setTabValue] = React.useState(0);
    const [error, setError] = useState(''); // TODO delete
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [totalSelected, setTotalSelected] = useState(0)

    const [fileName, setFileName] = useState<string>('');
    const [downloadUrl, setDownloadUrl] = useState<string>('')
    const downloadAnchor = useRef(null);

    const onGridReady = (params: { api: any; columnApi: any; }) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit()
    }

    let width = useCurrentWidth();

    const getSelectedRowData = () => {
        setSelectedRows(gridApi.getSelectedNodes())
        setTotalSelected(gridApi.getSelectedNodes().reduce((a: any, b: any) => a + (b.data.Size || 0), 0))
    }

    useEffect(() => {
        if (gridApi && gridApi.getDisplayedRowCount()) {
            gridApi.sizeColumnsToFit()
        }
    }, [gridApi, width])

    // Fetch files in folder on render
    useEffect(
        () => {
            async function getFilesInFolder() {
                try {
                    changeLoading(true, 'Getting the files ready to view')
                    let response = await apiManager.getFilesInFolder(token);
                    setData(response.files)
                    setPageTitle(response.directoryName)
                    setRowData(response.files)
                    if (response.readme) {
                        setReadMeLink(response.readme.Key)
                    }
                    setDlTokenValidDays(response.dlTokenValidDays)
                    changeLoading(false)
                } catch (ex: any) {
                    changeLoading(false)
                    await simpleErrorAlert(ex.response.data)
                    window.location.href = "https://www.decode.com/summarydata/";
                }
            }
            getFilesInFolder().catch(err => console.error(err))
        }, [token]);

    const getURLsFromSelectedRowData = () => {
        let urls = ""
        let baseUrl = `${process.env.REACT_APP_API_URL}/s3/download?token=${token}&file=`
        // @ts-ignore
        let rows = gridApi.getSelectedNodes();
        for (const row of rows) {
            urls = urls + (baseUrl + row.data.Key + '\n')
            urls = urls + (baseUrl + row.data.Key.slice(0, -2) + "md5sum\n")
        }
        return urls
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowData: String[] = data.filter((file: { Key: string; }) => {
            return file.Key.toLowerCase().includes(event.target.value.toLowerCase())
        })
        setRowData(newRowData)
    };

    const handleClickOpen = async () => {
        setOpen(true);
        await setUrls(getURLsFromSelectedRowData())
    };

    const handleClose = () => {
        setOpen(false);
    };

    const downloadUrlFile = () => {
        fileDownload(urls, 'urls.txt');
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    // @ts-ignore
    const onRowSelected = async () => {
        await getSelectedRowData()
    }

    function errorClose() {
        setError('')
    }

    function changeLoading(show: boolean, text: string = '') {
        setLoadingText(text)
        setLoading(show)
    }

    function replaceFileEnding(file: string, ending: string) {
        let arr = file.split('.')
        arr[arr.length - 1] = ending;
        return arr.join('.')
    }

    function niceBytesFormat(params: { value: any; }) {
        return niceBytes(params.value);
    }

    const downloadFile = async (file: string) => {
        try {
            changeLoading(true, 'Getting your file ready to download')
            // let res = await apiManager.downloadFile(token, file);
            // let contentDispositionHeader = res.headers['content-disposition'];
            // let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
            // await fileDownload(res.data, result.replace(/"/g, ''));
            let res = await apiManager.getFileInfo(token, file)
            debugger
            setFileName(file)
            setDownloadUrl(res.url)
            // @ts-ignore
            downloadAnchor.current.click()
            changeLoading(false)
            setFileName('')
            setDownloadUrl('')
        } catch (ex: any) {
            handleClose()
            changeLoading(false)
            await simpleErrorAlert(ex.response.data)
        }
    }

    function getTableBody() {
        return (
            <TableBody>
                {selectedRows.map((row) => (
                    <TableRow
                        key={row.data.Key}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell component="th" scope="row">
                            {row.data.Key}
                        </TableCell>
                        <TableCell align="right">{niceBytes(row.data.Size)}</TableCell>
                        <TableCell align="right">
                            <IconButton
                                color="primary"
                                aria-label="download file"
                                component="span"
                                onClick={() => downloadFile(row.data.Key)}
                            >
                                <FileDownloadIcon/>
                            </IconButton>
                        </TableCell>
                        <TableCell align="right">
                            <IconButton
                                color="secondary"
                                aria-label="download md5sum"
                                component="span"
                                onClick={() => downloadFile(replaceFileEnding(row.data.Key, 'md5sum'))}
                            >
                                <FileDownloadIcon/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
                <TableRow
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                >
                    <TableCell component="th" scope="row">Total</TableCell>
                    <TableCell align="right">
                        {niceBytes(totalSelected)}
                    </TableCell>
                </TableRow>
            </TableBody>
        )
    }

    return (
        <>
            {loading &&
            <LoadingScreen text1={loadingText}/>
            }
            {pageTitle &&
            <>
                <Typography component='div' align='left'>
                    <Box display='inline'>Summary data for:</Box> <Box sx={{fontWeight:'bold'}} display='inline'>{pageTitle}</Box>
                </Typography>
                <br/>
            </>
            }
            <Typography
                align='left'
            >
                Select the files you want to download. You can search for any text in the file name.
                When selecting the "Download Files" button you will be moved to another page to handle the download.
                If you select {process.env.REACT_APP_MAX_SINGLE_DOWNLOAD_LIMIT} or more files a text file for bulk
                download will appear.
            </Typography>
            <br/>
            <div className="ag-theme-alpine"
                 style={{height: '60vh', width: '90%', margin: 'auto', textAlign: 'center'}}>
                {readMeLink &&
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    marginBottom: '20px',
                }}>
                    <Button variant="contained" style={{textTransform: 'none', minWidth: '122px'}}
                            onClick={() => downloadFile(readMeLink)}>ReadMe File</Button>
                </div>
                }
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    marginBottom: '20px'
                }}>
                    <button type="button"
                            className="btn btn-primary"
                            disabled={selectedRows.length === 0}
                            onClick={handleClickOpen}
                            style={{minWidth: '122px'}}
                    >
                        {selectedRows.length > 1 ? "Download Files" : "Download File"}
                    </button>
                    <h5>Total Files selected: {selectedRows.length}</h5>
                    <h5>Total Size: {niceBytes(totalSelected)}</h5>
                    <input type="text"
                           className="form-control"
                           placeholder="Search..."
                           style={{width: "70%"}}
                           id="searchInput"
                           onChange={onChange}/>
                </div>
                {error &&
                <div className="alert alert-dismissible alert-primary">
                    <button type="button" className="close" data-dismiss="alert" onClick={errorClose}>&times;</button>
                    {error}
                </div>
                }
                <AgGridReact
                    onGridReady={onGridReady}
                    rowData={rowData}
                    pagination={true}
                    rowSelection={'multiple'}
                    rowMultiSelectWithClick={true}
                    onSelectionChanged={onRowSelected}
                    onCellClicked={errorClose}>
                    <AgGridColumn headerName="Name"
                                  field="Key"
                                  checkboxSelection={true}
                                  headerCheckboxSelection={true}
                                  resizable={true}
                                  cellStyle={{"textAlign": 'left'}}
                    />
                    <AgGridColumn headerName="Size"
                                  field="Size"
                                  resizable={true}
                                  maxWidth={100}
                                  valueFormatter={niceBytesFormat}
                                  cellStyle={{"textAlign": 'left'}}
                    />
                </AgGridReact>
            </div>

            <Dialog open={open} onClose={handleClose} fullWidth={true} fullScreen maxWidth={false}
                    style={{width: "100vw", padding: 120}}
            >
                <DialogTitle id="form-dialog-title">Download Data</DialogTitle>
                <DialogContent>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                            {selectedRows.length < parseInt(process.env.REACT_APP_MAX_SINGLE_DOWNLOAD_LIMIT as string) &&
                            <Tab label="Files" id="0"/>
                            }
                            <Tab label={selectedRows.length > 1 ? "Bulk Urls" : "Urls"} id="1"/>
                        </Tabs>
                    </Box>
                    {selectedRows.length < parseInt(process.env.REACT_APP_MAX_SINGLE_DOWNLOAD_LIMIT as string) &&
                    <TabPanel value={tabValue}
                              index={selectedRows.length < parseInt(process.env.REACT_APP_MAX_SINGLE_DOWNLOAD_LIMIT as string) ? 0 : 1}>
                        <Typography
                            align='left'
                        >
                            Use the download icon to download the file/s.
                            There is an option to download a md5sum file to verify that the file has not changed as a
                            result of a faulty file transfer or a disk error.
                        </Typography>
                        <br/>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Filename</TableCell>
                                        <TableCell align="right">Size</TableCell>
                                        <TableCell align="right">Download file</TableCell>
                                        <TableCell align="right">Download md5sum</TableCell>
                                    </TableRow>
                                </TableHead>
                                {getTableBody()}
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    }
                    <TabPanel value={tabValue}
                              index={selectedRows.length < parseInt(process.env.REACT_APP_MAX_SINGLE_DOWNLOAD_LIMIT as string) ? 1 : 0}>
                        <Typography
                            align='left'
                        >
                            The following links are valid for {dlTokenValidDays} {dlTokenValidDays > 1 ? "days" : "day"}.
                        </Typography>
                        <Typography
                            align='left'
                        >
                            To download multiple files we recommend downloading the URLs as a text file and either
                            writing your own script or using e.g. <a href={'https://aria2.github.io/'}
                                                                     target="_blank" rel="noreferrer">aria2</a> to download the files.
                        </Typography>
                        <br/>
                        <TextField
                            label={'Bulk Download URLs'}
                            placeholder="MultiLine with rows: 2"
                            multiline
                            rows={20}
                            value={urls}
                            disabled
                            fullWidth={true}
                            style={{backgroundColor: "#eceff0"}}
                        />
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                aria-label="download file"
                                component="span"
                                style={{marginTop: 15}}
                                onClick={() => downloadUrlFile()}
                            >
                                <FileDownloadIcon/><p style={{fontSize: 15, marginTop: 12}}> Download text file with
                                links </p>
                            </Button>
                            <h5>Total size of selected files: {niceBytes(totalSelected)}</h5>
                        </div>
                    </TabPanel>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                            color="primary">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
            <a style={{"display": "none"}}
               download={fileName}
               href={downloadUrl}
               ref={downloadAnchor}
            >download it</a>
        </>
    );
}
