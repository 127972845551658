import ReCAPTCHA from "react-google-recaptcha";
import React from "react";

let recaptcha = (props) => {
    function onChangeReCAPTCHA(value) {
        props.setRecaptchaValue(value)
    }

    return (
        <ReCAPTCHA
            sitekey="6LcNGacoAAAAAO1YUnOqxR97xBD1DlN-az6YhxG0"
            onChange={onChangeReCAPTCHA}
        />
    )
}

export default recaptcha