import Swal from "sweetalert2";

function simpleErrorAlert(text) {
    return Swal.fire({
        icon: 'error',
        title: text,
        confirmButtonColor: "#ac0533",
        confirmButtonText: 'Back'
    })
}

function niceBytes(bytes) {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let l = 0, n = parseInt(bytes, 10) || 0;
    while (n >= 1024 && ++l) {
        n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l]);
}

export {
    simpleErrorAlert,
    niceBytes
}