import React, {Component} from "react";

interface LoadingScreenProps {
    text1?: string;
    text2?: string;
}

export default class LoadingScreen extends Component<LoadingScreenProps> {
    render() {
        let text1 = <></>
        let text2 = <></>
        if (this.props.text1) {
            text1 = <> <br/> <p>{this.props.text1}</p> </>
        }
        if (this.props.text2) {
            text2 = <> <br/> <p>{this.props.text2}</p> </>
        }
        return (
            <div className="loadingScreen">
                <div>
                    <div className="loader">
                    </div>
                    {text1}
                    {text2}
                </div>
            </div>
        );
    }
}