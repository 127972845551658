import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import ReCAPTCHA from "../recaptcha/recaptcha";
import Swal from "sweetalert2";
import apiManager from "../../utils/apiManager";
import {niceBytes, simpleErrorAlert} from "../../utils/util"
import LoadingScreen from "../loadingScreen/loadingScreen";


export default function Form(props: { isFolder: boolean; }) {
    const {folderName} = useParams<{ folderName: string }>();
    const {fileName} = useParams<{ fileName: string }>();
    const {fileFolder} = useParams<{ fileFolder: string }>();
    const [fileSize, setFileSize] = useState<number>(0);
    const [checkbox, setCheckbox] = useState<boolean>(false);
    const [fullName, setFullName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [redirectUrl, setRedirectUrl] = useState<string>("https://www.decode.com/summarydata/");
    const [affiliation, setAffiliation] = useState<string>('');
    const [recaptcha, setRecaptcha] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const isFolder = props.isFolder

    useEffect(() => {
        async function getFolderInfo() {
            try {
                changeLoading(true, 'Getting the form ready')
                if (isFolder) {
                    let folderInfo = await apiManager.getFolderInfo(folderName)
                    if (folderInfo.redirect_url){
                        setRedirectUrl(folderInfo.redirect_url)
                    }
                } else {
                    const fileSize = await apiManager.getFileSize(fileFolder, fileName)
                    setFileSize(fileSize.ContentLength)
                }
                changeLoading(false)

            } catch (ex: any) {
                changeLoading(false)
                await simpleErrorAlert(ex.response.data)
                window.location.href = redirectUrl;
            }
        }
        getFolderInfo().catch(err => console.error(err))
    }, [fileName, folderName, fileFolder, isFolder]);

    function changeLoading(show: boolean, text: string = '') {
        setLoadingText(text)
        setLoading(show)
    }

    async function postForm() {
        const body = {
            name: fullName,
            affiliation: affiliation,
            email: email,
            accepted: checkbox,
            reCaptcha: recaptcha,
            folderName: folderName,
            fileName: `${fileFolder}/${fileName}`,
        }
        const config = {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }

        if (email && !email.includes("@") && !email.includes(".")) {
            await Swal.fire({
                title: "Incorrect e-mail address",
                text: "You have to provide a valid e-mail address",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#ac0533",
                showCancelButton: false
            })

        } else {
            try {
                changeLoading(true, 'Submitting form')
                let response = await apiManager.submitForm(body, config)
                if (response.status === 201) {
                    Swal.fire({
                        title: 'Request received',
                        text: 'You will receive an e-mail shortly with a link to the requested data',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        confirmButtonColor: "#ac0533"
                    }).then(() => {
                        window.location.href = redirectUrl;
                    })
                } else {
                    await simpleErrorAlert(response.data)
                    // request failed for some reason
                }
                changeLoading(false)
            } catch (ex: any) {
                await simpleErrorAlert(ex.response.data)
                changeLoading(false)
            }
        }
    }


    return (
        <>
            {loading &&
            <LoadingScreen text1={loadingText}/>
            }
            <div className="container" style={{'textAlign': 'left'}}>
                <div className="row">
                    <h3>Terms</h3>
                </div>
                <div className="row">
                    <p>In order to download the selected file we ask you to fill in the following form. By
                        continuing you
                        agree that:
                    </p>
                    <ul>
                        <li>
                            Your email and institute information will be logged for sending you the link to the
                            requested file and for statistical and security reasons. Your information will not be
                            sent
                            to a third party.
                        </li>
                        <li>
                            Usage of the downloaded data is for scientific purposes only.
                        </li>
                        <li>
                            You will not redistribute the downloaded files.
                        </li>
                    </ul>

                </div>
                <div className="row">
                    <div className='col-12'>
                        <div className="row">
                            <div className="col-6">
                                <input type="checkbox" id="termsCheckbox" name="accepted" style={{"marginRight": "5px"}}
                                       readOnly
                                       onChange={() => setCheckbox(!checkbox)} required/>
                                <label htmlFor="termsCheckbox" id="termsConfirmName">I accept the aforementioned
                                    terms.</label>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        {!loading &&
                        <>
                            <div className="col-6">
                                <div className='form-group'>
                                    <label htmlFor='name' className='cmp-title cmp-title--tab'>Name</label>
                                    <input id='name' name='name' type='text' className='form-control' minLength={3}
                                           value={fullName} onChange={e => setFullName(e.target.value)}
                                           placeholder="Enter your name" required/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className='form-group'>
                                    <label htmlFor='email' className='cmp-title cmp-title--tab'>Email</label>
                                    <input id='email' name='email' type='email' className='form-control'
                                           value={email} onChange={e => setEmail(e.target.value)}
                                           placeholder="Enter your email" required/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className='form-group'>
                                    <label htmlFor='affiliation'
                                           className='cmp-title cmp-title--tab'>Institution</label>
                                    <input id='affiliation' name='affiliation' type='text' className='form-control'
                                           minLength={2}
                                           value={affiliation} onChange={e => setAffiliation(e.target.value)}
                                           placeholder="Enter your institution" required/>
                                </div>
                            </div>
                        </>
                        }
                        <br/>
                        <div className="col-6">
                            <ReCAPTCHA setRecaptchaValue={setRecaptcha}/>
                        </div>
                        <br/>
                        <div className="col-6">
                            <>
                                <p><strong>Name: </strong> {isFolder ? folderName : fileName} <br/></p>
                                {!isFolder &&
                                <p><strong>Size: </strong> {niceBytes(fileSize)} <br/></p>
                                }
                            </>
                        </div>
                        <div className="row">
                            <div className='col-4'>
                                <button type='submit' id="submit-btn"
                                        className='btn btn-primary btn-narrow btn-fill'
                                        onClick={postForm}
                                        disabled={!(checkbox && fullName && email && affiliation && recaptcha)}
                                        style={{padding: "0.575rem 1.75rem", marginTop: "1px"}}>Send
                                </button>
                            </div>
                        </div>
                        <input id='filename' name='filename' type='text' className='form-control'
                               value="<%= fileName %>"
                               readOnly
                               style={{display: 'none'}}/>
                    </div>
                </div>
            </div>
        </>
    );
}
